const basePath = '/wt/api/v1';
const endpoints = {
    getPageDataByIds: (ids, baseUrl = basePath) => `${baseUrl}/bookmarks/?ids=${ids.join(',')}`,
    getSearchResults: (searchString, baseUrl = basePath) =>
        `${baseUrl}/search-filter/?${searchString}`,
    getSearchSuggestions: (searchString, parent, baseUrl = basePath) =>
        `${baseUrl}/search-filter-suggestions/?query=${searchString}&parent=${parent}`,
    sendSearchDupes: (baseUrl = basePath) =>
        `${baseUrl}/dupes/`,
    sendArticleFeedback: (baseUrl = basePath) => `${baseUrl}/articlefeedbackentry/`,
    sendArticleFeedbackComment: (feedbackId, baseUrl = baseUrl) =>
        `${basePath}/articlefeedbackentry/${feedbackId}/`,
};

const getPageDataByIds = async (ids) => {
    const response = await fetch(endpoints.getPageDataByIds(ids));
    return await response.json();
};

const getSearchResults = async (
    page,
    searchInput,
    activeChips,
    activeRegions,
    parent,
    pinnedCategory,
    sorting,
    abortController
) => {
    let signal;

    if (abortController) {
        signal = abortController.signal;
    }

    let regions = '';
    if (activeRegions && activeRegions.length > 0) {
        regions = activeRegions.join(',');
    }

    let categories = '';
    if (activeChips && activeChips.length > 0) {
        categories = activeChips.join(',');
    }

    const searchString = `query=${searchInput}&categories=${categories}&parent=${parent}&page=${page}&regions=${regions}&pinned_category=${
        pinnedCategory || ''
    }&sorting=${sorting}`;
    return fetch(endpoints.getSearchResults(searchString), { signal });
};

const getSearchSuggestions = async (searchString, parent) => {
    const response = await fetch(
        endpoints.getSearchSuggestions(searchString, parent)
    );
    return await response.json();
};

const sendSearchDupes = async (searchString, honeypotValue, nonce, baseUrl = basePath) => {
    if (honeypotValue !== '') {
        return;
    }

    const csrf = getCookie('csrftoken');
    const response = await fetch(
        endpoints.sendSearchDupes(baseUrl),
        {
            method: 'POST',
            body: JSON.stringify({ query: searchString, honeypot: honeypotValue }),
            headers: {
                'Content-Type': 'application/json',
                'X-Nonce': nonce,
                ...(csrf && {'X-Csrftoken': csrf}),
            },
        },
    );
    return await response.json();
};

const sendArticleFeedback = async (articleId, rating) => {
    const response = await fetch(endpoints.sendArticleFeedback, {
        method: 'POST',
        body: JSON.stringify({ article: articleId, rating }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return await response.json();
};

const sendArticleFeedbackComment = async (feedbackId, comment) => {
    const response = await fetch(
        endpoints.sendArticleFeedbackComment(feedbackId),
        {
            method: 'PATCH',
            body: JSON.stringify({ comment }),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return await response.json();
};

const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

export {
    getPageDataByIds,
    getSearchResults,
    getSearchSuggestions,
    sendSearchDupes,
    sendArticleFeedback,
    sendArticleFeedbackComment,
};
