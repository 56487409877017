import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './NavigationSearch.module.scss';
import { useTranslation } from 'next-i18next';
import { getSearchSuggestions } from '../../utils/api';
import classNames from 'classnames';
import FadeIn from '../../transitions/FadeIn';

const useDebounce = (value, delay = 250) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(timeout);
    }, [delay, value]);

    return debouncedValue;
};

/*
 * Suggestions should be shown only when:
 * - NavigationSearch is focussed and there are suggestions
 * - A search suggestion is focused
 */

const NavigationSearch = ({ searchPageUrl, onClose }) => {
    const { t } = useTranslation('common');
    const [searchInput, setSearchInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [focussed, setFocussed] = useState('');
    const debouncedInput = useDebounce(searchInput);

    useEffect(() => {
        const getItems = async () => {
            const items = await getSearchSuggestions(debouncedInput, '');
            setSuggestions(items);
        };
        if (debouncedInput) {
            getItems();
        }
    }, [debouncedInput]);

    /* Key handling */
    useEffect(() => {
        const handleBlockScroll = (e) => {
            if (e.keyCode === 40 || e.keyCode === 38) {
                e.preventDefault();
            }
            // const UP = 38;
            // const DOWN = 40;
        };
        const handleKeyPress = (e) => {
            const UP = 38;
            const DOWN = 40;
            const ESC = 27;
            const ENTER = 13;
            const currentElement = document.activeElement;
            const keyCode = e.keyCode;
            const isLink = currentElement
                ? currentElement.classList.value.indexOf(
                      styles['NavigationSearch__Link']
                  ) > -1
                : '';
            const isSearch = currentElement.id === 'search';

            if (isSearch && keyCode === DOWN) {
                document
                    .querySelector(
                        '#suggestions .NavigationSearch__Link:first-of-type'
                    )
                    .focus();
            }

            if (isSearch && keyCode === ENTER && searchInput) {
                window.location.href = `${searchPageUrl}?query=${encodeURIComponent(
                    searchInput
                )}`;
            }

            // We are at the first suggestion and press up
            if (
                !isSearch &&
                isLink &&
                keyCode === UP &&
                !currentElement.parentElement.previousSibling
            ) {
                // Takes the links parent (li) and finds its next sibling (li)
                // and finds its link and focusses
                document.querySelector('.NavigationSearch__Input').focus();
                currentElement.parentElement.previousSibling
                    .querySelector('a')
                    .focus();
            }

            // We are not at the first suggestion and press up
            if (
                !isSearch &&
                isLink &&
                keyCode === UP &&
                currentElement.parentElement.previousSibling
            ) {
                // Takes the links parent (li) and finds its previous sibling (li)
                // and finds its link and focusses
                currentElement.parentElement.previousSibling
                    .querySelector('a')
                    .focus();
            }

            // We are not at a link and press down
            if (
                !isSearch &&
                isLink &&
                keyCode === DOWN &&
                currentElement.parentElement.nextSibling
            ) {
                // Takes the links parent (li) and finds its next sibling (li)
                // and finds its link and focusses
                currentElement.parentElement.nextSibling
                    .querySelector('a')
                    .focus();
            }

            if (keyCode === ESC) {
                onClose();
                setShowSuggestions(false);
                document.body.classList.remove('Dimmed');
                document.body.classList.remove('NavSearching');
            }
        };

        document.addEventListener('keyup', handleKeyPress);
        document.addEventListener('keydown', handleBlockScroll);

        return () => {
            document.removeEventListener('keyup', handleKeyPress);
            document.removeEventListener('keydown', handleBlockScroll);
        };
    }, [searchInput, searchPageUrl, onClose]);

    useEffect(() => {
        const handleFocus = (e) => {
            setFocussed(e.target);
        };
        const handleClick = (e) => {
            if (e.target && e.target.classList.contains('Dimmed')) {
                onClose();
                setShowSuggestions(false);
                document.body.classList.remove('Dimmed');
                document.body.classList.remove('NavSearching');
            }
        };

        document.addEventListener('focusin', handleFocus, true);
        document.addEventListener('click', handleClick, true);
        document.querySelector('.NavigationSearch__Input').focus();

        return () => {
            document.removeEventListener('focusin', handleFocus, true);
            document.removeEventListener('click', handleClick, true);
        };
    }, [onClose]);

    useEffect(() => {
        // if (suggestions.length < 1) {
        //     setShowSuggestions(false);
        //     document.body.classList.remove('Dimmed');
        //     return;
        // }
        const focusShouldShow =
            (focussed && focussed.id == 'search') ||
            focussed.localName == 'li' ||
            (focussed &&
                focussed.classList.contains(
                    styles['NavigationSearch__Link']
                )) ||
            (focussed &&
                focussed.classList.contains(
                    styles['NavigationSearch__Button']
                )) ||
            (focussed &&
                focussed.classList.contains(
                    styles['NavigationSearch__Cancel']
                )) ||
            (focussed &&
                focussed.classList.contains(styles['NavigationSearch__Reset']));

        if (focusShouldShow) {
            setShowSuggestions(true);
            document.body.classList.add('Dimmed');
            document.body.classList.add('NavSearching');
        } else {
            setShowSuggestions(false);
            document.body.classList.remove('Dimmed');
            document.body.classList.remove('NavSearching');
        }
    }, [suggestions, focussed]);

    const suggestionsClasses = classNames(
        styles['NavigationSearch__Suggestions'],
        {
            [styles['NavigationSearch__Suggestions--HasSuggestions']]: true,
        }
    );
    const wrapperClasses = classNames(styles['NavigationSearch__Wrap'], {
        [styles['NavigationSearch__Wrap--ShowsSuggestions']]: showSuggestions,
    });

    return (
        <div className={styles['NavigationSearch']}>
            <div className={wrapperClasses}>
                <input
                    className={
                        styles['NavigationSearch__Input'] +
                        ' NavigationSearch__Input'
                    }
                    value={searchInput}
                    placeholder={t('search.placeholder')}
                    id="search"
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                {searchInput !== '' && (
                    <button
                        onClick={() => setSearchInput('')}
                        className={styles['NavigationSearch__Reset']}
                        aria-label={t('search.resetSearchAriaLabel')}></button>
                )}
                <button
                    className={styles['NavigationSearch__Button']}
                    aria-label={t('search.searchAriaLabel')}
                    onClick={() => {
                        window.location.href = `${searchPageUrl}?query=${encodeURIComponent(
                            searchInput
                        )}`;
                    }}></button>

                <button
                    className={styles['NavigationSearch__Cancel']}
                    aria-label={t('search.searchAriaClose')}
                    onClick={() => {
                        onClose();
                        setShowSuggestions(false);
                        document.body.classList.remove('Dimmed');
                        document.body.classList.remove('NavSearching');
                    }}></button>

                <FadeIn visible={showSuggestions}>
                    <div
                        role="region"
                        aria-live="polite"
                        id="suggestions"
                        className={suggestionsClasses}>
                        <ol>
                            {suggestions.map((item) => {
                                return (
                                    <li key={item.href}>
                                        <a
                                            className={
                                                styles['NavigationSearch__Link']
                                            }
                                            href={item.href}>
                                            {item.title}
                                        </a>
                                    </li>
                                );
                            })}
                            {searchInput && (
                                <li
                                    className={
                                        styles['NavigationSearch__SearchLink']
                                    }>
                                    <a
                                        className={
                                            styles['NavigationSearch__Link']
                                        }
                                        href={`${searchPageUrl}?query=${encodeURIComponent(
                                            searchInput
                                        )}`}>
                                        {`Search: "${searchInput}"`}
                                    </a>
                                </li>
                            )}
                        </ol>
                    </div>
                </FadeIn>
            </div>
        </div>
    );
};

NavigationSearch.propTypes = {
    searchPageUrl: PropTypes.string,
    onClose: PropTypes.func,
};

NavigationSearch.defaultProps = { searchPageUrl: '', onClose: () => {} };

export default NavigationSearch;
