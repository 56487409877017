import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ButtonToggle.module.scss';

const ButtonToggle = ({ onClick, className, untranslatedClassNames = [] }) => {
    const translatedClassNames = untranslatedClassNames.map((x) => styles[x]);
    return (
        <button
            className={classNames(className, translatedClassNames)}
            onClick={onClick}
        />
    );
};

ButtonToggle.propTypes = {
    props: PropTypes.shape({
        className: PropTypes.string,
        onClick: PropTypes.func,
    }),
    rawModifiers: PropTypes.arrayOf(PropTypes.string),
};

ButtonToggle.defaultProps = {
    className: styles['ButtonToggle'],
    rawModifiers: [],
};

export default ButtonToggle;
