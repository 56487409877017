import React from 'react';

// This variable will be true once the server-side hydration is completed.
let hydrationCompleted = false;

function useMediaQuery({ query, options = [] }) {
    // This defensive check is here for simplicity.
    // Most of the time, the match media logic isn't central to people tests.
    const supportMatchMedia =
        typeof window !== 'undefined' &&
        typeof window.matchMedia !== 'undefined';

    const {
        defaultMatches = false,
        noSsr = false,
        ssrMatchMedia = null,
    } = {
        ...options,
    };

    const [match, setMatch] = React.useState(() => {
        if ((hydrationCompleted || noSsr) && supportMatchMedia) {
            return window.matchMedia(query).matches;
        }
        if (ssrMatchMedia) {
            return ssrMatchMedia(query).matches;
        }

        // Once the component is mounted, we rely on the
        // event listeners to return the correct matches value.
        return defaultMatches;
    });

    React.useEffect(() => {
        hydrationCompleted = true;

        if (!supportMatchMedia) {
            return undefined;
        }

        const queryList = window.matchMedia(query);
        setMatch(queryList.matches);

        function handleMatchesChange() {
            setMatch(queryList.matches);
        }

        queryList.addListener(handleMatchesChange);
        return () => {
            queryList.removeListener(handleMatchesChange);
        };
    }, [query, supportMatchMedia]);

    return match;
}

export default useMediaQuery;
