import { createContext, useReducer } from 'react';

const CookieStateContext = createContext(null);

const useCookieStateContext = (categories) => {
    const [state, dispatch] = useReducer(cookieReducer, {
        categories: categories,
    });

    const handleCookieAcceptAll = () => {
        dispatch({
            type: 'ACCEPT_ALL_COOKIES',
        });
    };

    const handleCookieAcceptCategories = (categories) => {
        dispatch({
            type: 'ACCEPT_CATEGORIES',
            payload: {
                categories,
            },
        });
    };

    const handleCookieRejectAll = () => {
        dispatch({
            type: 'REJECT_ALL_COOKIES',
        });
    };

    const context = {
        state,
        handleCookieAcceptAll,
        handleCookieAcceptCategories,
        handleCookieRejectAll,
    };

    return context;
};

const cookieReducer = (state, action) => {
    switch (action.type) {
        case 'ACCEPT_ALL_COOKIES': {
            return {
                ...state,
                categories: [
                    'strict',
                    'performance',
                    'targeting',
                    'functionality',
                ],
            };
        }

        case 'ACCEPT_CATEGORIES': {
            return {
                ...state,
                categories: action.payload.categories,
            };
        }

        case 'REJECT_ALL_COOKIES': {
            return {
                ...state,
                categories: [],
            };
        }

        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

export { useCookieStateContext, CookieStateContext };
