const setCookie = (cname, cvalue) => {
    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const getCookie = (name) => {
    if (typeof window === 'undefined') {
        return '';
    }

    name = name + '=';
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let part = cookie[i];
        while (part.charAt(0) === ' ') {
            part = part.substring(1);
        }
        if (part.indexOf(name) === 0) {
            return part.substring(name.length, part.length);
        }
    }
    return '';
};

export { setCookie, getCookie };
