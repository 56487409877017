import storage from './localStorage';
import base64url from 'base64url';

// init

if (storage.getItem('bookmarks') === null) {
    storage.setItem('bookmarks', JSON.stringify([]));
}

// private

const getBookmarkBy = (propName, value) => {
    const foundBookmarks = getBookmarks().filter(
        (bookmark) => bookmark[propName] === value
    );

    return foundBookmarks.length ? foundBookmarks[0] : null;
};

const removeBookmarkBy = (propName, value) => {
    const bookmarks = getBookmarks().filter(
        (bookmark) => bookmark[propName] !== value
    );

    storage.setItem('bookmarks', JSON.stringify(bookmarks));
    executeUpdateCallbacks();
};

let cbs = [];
const executeUpdateCallbacks = () => {
    const updatedBookmarks = getBookmarks();
    cbs.forEach((cb) => cb(updatedBookmarks));
};

// public

const isBookmarked = (id) => getBookmark(id) !== null;

const getBookmarks = () => JSON.parse(storage.getItem('bookmarks'));

const getBookmarkIds = () =>
    getBookmarks().reduce(
        (ids, bookmark) => (bookmark.id ? [...ids, bookmark.id] : ids),
        []
    );

const hasBookmarks = () => getBookmarks().length > 0;

const getBookmark = (id) => getBookmarkBy('id', id);

const getBookmarkByUrl = (url) => getBookmarkBy('url', url);

const addBookmark = ({ id, url }) => {
    if (isBookmarked(id)) {
        return false;
    }

    let bookmarks = getBookmarks();

    storage.setItem(
        'bookmarks',
        JSON.stringify([
            ...bookmarks,
            {
                id,
                url,
            },
        ])
    );

    executeUpdateCallbacks();

    return true;
};

const removeBookmark = (id) => removeBookmarkBy('id', id);

const removeBookmarkByUrl = (url) => removeBookmarkBy('url', url);

const onBookmarksUpdated = (cb) => (cbs = [...cbs, cb]);

const removeOnBookmarksUpdated = (cb) => (cbs = cbs.filter((fn) => fn !== cb));

// encode ids with a naive checksum to catch incorrectly/partially copied share urls
const encodeBookmarkIds = (bookmarkIds) => {
    const checksum = bookmarkIds.reduce((sum, id) => sum + id, 0);
    return base64url([...bookmarkIds, checksum].join(','));
};

const decodeBookmarkIds = (data) => {
    let ids = base64url
        .decode(data)
        .split(',')
        .map((id) => Number(id));

    const checksum = ids.pop();
    const calcedChecksum = ids.reduce((sum, id) => sum + id, 0);

    if (checksum !== calcedChecksum) {
        return null;
    }

    return ids;
};

export {
    isBookmarked,
    hasBookmarks,
    addBookmark,
    removeBookmark,
    removeBookmarkByUrl,
    getBookmark,
    getBookmarkByUrl,
    getBookmarks,
    getBookmarkIds,
    encodeBookmarkIds,
    decodeBookmarkIds,
    onBookmarksUpdated,
    removeOnBookmarksUpdated,
};
