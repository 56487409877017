import React from 'react';
import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import styles from './SlideInFromTop.module.scss';

const SlideInFromTop = ({ children, visible, timeout, ...props }) => {
    return (
        <CSSTransition
            classNames={styles['SlideInFromTop']}
            in={visible}
            timeout={timeout}
            unmountOnExit
            {...props}>
            {children}
        </CSSTransition>
    );
};

SlideInFromTop.propTypes = {
    children: PropTypes.node,
    visible: PropTypes.bool,
    timeout: PropTypes.number,
};

SlideInFromTop.defaultProps = {
    children: '',
    visible: undefined,
    timeout: 300,
};

export default SlideInFromTop;
