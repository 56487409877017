const breakpoints = {
    s: 375,
    sm: 414,
    m: 768,
    l: 1280,
    xl: 1440,
    xxl: 1680,
};

export default breakpoints;
