import React, { useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './NavigationMobile.module.scss';

import NavigationFavorite from '../NavigationFavorite';
import NavigationSearch from '../NavigationSearch';
import FadeIn from '../../transitions/FadeIn';
import { CookieStateContext } from '../../containers/BasePage/state';
import ButtonToggle from '../ButtonToggle';
import { useTranslation } from 'next-i18next';

const NavigationMobile = ({
    menu,
    searchPageUrl,
    favoritesPageUrl,
    languages,
    handleDisable,
}) => {
    const { t } = useTranslation('common');
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const handleMenuToggle = useCallback(() => {
        handleDisable();
        setMenuIsOpen((menuIsOpen) => !menuIsOpen);
    }, [handleDisable]);

    const { state } = useContext(CookieStateContext) || {};
    const cookieConsent = state?.categories?.includes('functionality');

    const menuClasses = classNames(styles['NavigationMobile'], {
        [styles['NavigationMobile--Open']]: menuIsOpen,
        ['NavigationMobile--Open']: menuIsOpen, // for headroom styling
    });

    return (
        <nav className={menuClasses}>
            <div className={styles['NavigationMobile__Container']}>
                <FadeIn visible={showSearch}>
                    <NavigationSearch
                        searchPageUrl={searchPageUrl}
                        onClose={() => setShowSearch(false)}
                    />
                </FadeIn>
                {!showSearch && (
                    <>
                        <a
                            href="/"
                            className={styles['NavigationMobile__Logotype']}>
                            <span className="sr-only">
                                Visit Sweden Logotype
                            </span>
                        </a>
                        <div className={styles['NavigationMobile__Actions']}>
                            <button
                                className={styles['NavigationMobile__Search']}
                                onClick={() => {
                                    if (
                                        typeof window !== 'undefined' &&
                                        window.location.pathname !== '/' &&
                                        searchPageUrl.indexOf(
                                            window.location.pathname
                                        ) > -1
                                    ) {
                                        document
                                            .querySelector(
                                                '.SearchFilter__HeadingLabel'
                                            )
                                            .scrollIntoView({
                                                behavior: 'smooth',
                                            });
                                    } else {
                                        setShowSearch(true);
                                    }
                                }}>
                                <span className="sr-only">
                                    {t('navigation.search')}
                                </span>
                            </button>

                            {favoritesPageUrl && cookieConsent && (
                                <NavigationFavorite
                                    favoritesPageUrl={favoritesPageUrl}
                                />
                            )}

                            <button
                                className={styles['NavigationMobile__Toggle']}
                                onClick={handleMenuToggle}>
                                {menuIsOpen ? (
                                    <span className="sr-only">
                                        {t('navigation.close')}
                                    </span>
                                ) : (
                                    <span className="sr-only">
                                        {t('navigation.open')}
                                    </span>
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className={styles['NavigationMobile__ItemsContainer']}>
                <div className={styles['NavigationMobile__Items']}>
                    {menu.map((item, index) => (
                        <SubNavigation key={index} {...item} />
                    ))}

                    <MobileLanguageSwitcher languages={languages} />
                </div>
            </div>
        </nav>
    );
};

NavigationMobile.propTypes = {
    menu: PropTypes.array,
    searchPageUrl: PropTypes.string,
    favoritesPageUrl: PropTypes.string,
    languages: PropTypes.array,
    handleDisable: PropTypes.func,
};

NavigationMobile.defaultProps = {
    menu: [],
    languages: [],
    searchPageUrl: '',
    favoritesPageUrl: '',
    handleDisable: () => false,
};

const MobileLanguageSwitcher = ({ languages }) => {
    const { t } = useTranslation('common');
    const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
    const handleSubMenuToggle = useCallback((e) => {
        e.preventDefault();
        setSubMenuIsOpen((subMenuIsOpen) => !subMenuIsOpen);
    }, []);
    const activeLanguage = languages.filter((l) => l.isActive)[0] || {};

    let buttonToggleModifiers = ['ButtonToggle', 'ButtonToggle--Grey300'];

    if (!subMenuIsOpen) {
        buttonToggleModifiers = [
            ...buttonToggleModifiers,
            'ButtonToggle--Language',
        ];
    }

    if (subMenuIsOpen) {
        buttonToggleModifiers = [
            ...buttonToggleModifiers,
            'ButtonToggle--LanguageClose',
        ];
    }

    return (
        <>
            <div className={styles['NavigationMobile__ItemContainer']}>
                <div
                    className={
                        styles['NavigationMobile__Item'] +
                        ' ' +
                        styles['NavigationMobile__Item--TopLevel']
                    }>
                    <span className={styles['NavigationMobile__Language']}>
                        {t('navigation.language')}
                    </span>
                    <span
                        className={styles['NavigationMobile__ActiveLanguage']}>
                        {activeLanguage.title}
                    </span>
                    <div className={styles['NavigationMobile__ButtonToggle']}>
                        <ButtonToggle
                            untranslatedClassNames={buttonToggleModifiers}
                            onClick={handleSubMenuToggle}
                        />
                    </div>
                </div>
            </div>
            {subMenuIsOpen &&
                languages &&
                languages.map((language, index) => (
                    <div
                        key={index}
                        className={classNames(
                            styles['NavigationMobile__ItemContainer'],
                            styles['NavigationMobile__ItemContainer--SubLevel'],
                            styles['NavigationMobile__Item--Language']
                        )}>
                        <span
                            className={classNames(
                                styles['NavigationMobile__Checkbox'],
                                {
                                    [styles[
                                        'NavigationMobile__Checkbox--Active'
                                    ]]: language.isActive,
                                }
                            )}
                        />
                        {language.title}
                        <a
                            className={styles['NavigationMobile__ItemLink']}
                            href={language.href}></a>
                    </div>
                ))}
        </>
    );
};

MobileLanguageSwitcher.propTypes = {
    languages: PropTypes.array,
};

const SubNavigation = ({ title, pageType, items, href }) => {
    const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
    const handleSubMenuToggle = useCallback((e) => {
        e.preventDefault();
        setSubMenuIsOpen((subMenuIsOpen) => !subMenuIsOpen);
    }, []);
    let buttonToggleClasses = ['ButtonToggle', 'ButtonToggle--Grey300'];

    if (!subMenuIsOpen) {
        buttonToggleClasses = [...buttonToggleClasses, 'ButtonToggle--Plus'];
    }

    if (subMenuIsOpen) {
        buttonToggleClasses = [...buttonToggleClasses, 'ButtonToggle--Minus'];
    }

    const topNavigationClasses = classNames(
        styles['NavigationMobile__Item'],
        styles['NavigationMobile__Item--TopLevel'],
        { [styles[`NavigationMobile__Item--${pageType}`]]: pageType }
    );

    const hasSubMenuLinkItems =
        items &&
        !!items.length &&
        items.some((firstLevelLists) => {
            return (
                firstLevelLists.items &&
                firstLevelLists.items.some((secondLevelLists) => {
                    return (
                        secondLevelLists.links &&
                        !!secondLevelLists.links.length &&
                        !!secondLevelLists.links.length
                    );
                })
            );
        });

    return (
        <>
            <div className={styles['NavigationMobile__ItemContainer']}>
                <a className={topNavigationClasses} href={href}>
                    {title}
                </a>
                {hasSubMenuLinkItems && (
                    <div className={styles['NavigationMobile__ButtonToggle']}>
                        <ButtonToggle
                            untranslatedClassNames={buttonToggleClasses}
                            onClick={handleSubMenuToggle}
                        />
                    </div>
                )}
            </div>
            {subMenuIsOpen &&
                items &&
                items.map((lists, listsIndex) => (
                    <React.Fragment key={listsIndex}>
                        {lists.items &&
                            lists.items.map(
                                ({ links, showArrowIcon }, listIndex) => (
                                    <React.Fragment key={listIndex}>
                                        {links &&
                                            links.map((link, linkIndex) => (
                                                <SubNavigationItem
                                                    key={linkIndex}
                                                    {...link}
                                                    showArrowIcon={
                                                        showArrowIcon
                                                    }
                                                />
                                            ))}
                                    </React.Fragment>
                                )
                            )}
                    </React.Fragment>
                ))}
        </>
    );
};

SubNavigation.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    pageType: PropTypes.string,
    items: PropTypes.array,
};

const SubNavigationItem = ({
    title,
    href,
    pageType,
    isEditorial,
    type,
    showArrowIcon,
}) => {
    const subNavigationClasses = classNames(
        styles['NavigationMobile__ItemContainer'],
        styles['NavigationMobile__ItemContainer--SubLevel'],
        {
            [styles[`NavigationMobile__Item--${pageType}`]]:
                pageType && !isEditorial,
        },
        { [styles['NavigationMobile__Item--Editorial']]: isEditorial },
        { [styles['Navigation__DropdownLink--Arrow']]: showArrowIcon },
        {
            [styles['NavigationMobile__Item--City']]:
                !showArrowIcon && type === 'city',
        },
        {
            [styles['NavigationMobile__Item--Region']]:
                !showArrowIcon && type === 'region',
        }
    );

    const subNavigationLinkClasses = classNames(
        styles['NavigationMobile__Item'],
        styles['NavigationMobile__Item--SubLevel']
    );

    return (
        <div className={subNavigationClasses}>
            <span className={subNavigationLinkClasses}>{title}</span>
            <a href={href} className={styles['NavigationMobile__ItemLink']}>
                <span className="sr-only">{title}</span>
            </a>
        </div>
    );
};

SubNavigationItem.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    pageType: PropTypes.string,
    isEditorial: PropTypes.bool,
    type: PropTypes.string,
    showArrowIcon: PropTypes.bool,
};

export default NavigationMobile;
