import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import SlideInFromTop from '../../transitions/SlideInFromTop';

import { useTranslation } from 'next-i18next';
import { setCookie, getCookie } from '../../utils/cookies';

import styles from './CrisisInformationBar.module.scss';

const CrisisInformationBar = ({ title, content, identifier }) => {
    const { t } = useTranslation('common');
    const [hidden, setHidden] = useState(true);

    const dismissCrisisInformation = () => {
        setHidden(true);
        setCookie(identifier, true);
    };

    useEffect(() => {
        if (!getCookie(identifier)) {
            setHidden(false);
        }
    }, [identifier]);

    if (!title || !content) {
        return null;
    }

    return (
        <SlideInFromTop visible={!hidden}>
            <div className={styles['CrisisInformationBar']}>
                <div className={styles['CrisisInformationBar__Container']}>
                    <div className={styles['CrisisInformationBar__Icon']} />
                    <div className={styles['CrisisInformationBar__Content']}>
                        <h3
                            className={
                                styles['CrisisInformationBar__Content__Title']
                            }>
                            {title}
                        </h3>
                        <div
                            className={
                                styles['CrisisInformationBar__Content__Text']
                            }
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </div>
                    <div className={styles['CrisisInformationBar__Close']}>
                        <Button
                            icon="Arrow"
                            text={t('crisisinformationbar.close')}
                            type={'Plain'}
                            onClick={dismissCrisisInformation}></Button>
                    </div>
                </div>
            </div>
        </SlideInFromTop>
    );
};

CrisisInformationBar.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    identifier: PropTypes.string,
};

CrisisInformationBar.defaultProps = {
    title: '',
    content: '',
    identifier: '',
};

export default CrisisInformationBar;
