import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import classNames from 'classnames';

const Button = ({
    children = '',
    link = '',
    label = '',
    text = '',
    onClick = () => {},
    icon = '',
    type = '',
    className = '',
    minWidth = null,
    isDownload = false,
    untranslatedModifiers = [],
    attrs = {},
}) => {
    const Tag = link ? 'a' : 'button';

    let props = {};
    let iconClasses;

    if (link) {
        props.href = link;
    }

    if (onClick) {
        props.onClick = onClick;
    }

    if (label) {
        props['aria-label'] = label;
    }

    if (isDownload) {
        props.download = true;
        props.target = '_self';
    }

    const classes = classNames(
        styles['Button'],
        {
            [styles['Button--Link']]: link,
            [styles[`Button--${type}`]]: type,
        },
        untranslatedModifiers.map((x) => styles[x]),
        className,
    );

    iconClasses = classNames(
        styles['Button__Icon'],
        styles[`Button__Icon--${icon}`]
    );

    const minWidthStyle = minWidth ? { minWidth } : {};

    return (
        <Tag {...props} {...attrs} style={minWidthStyle} className={classes}>
            {text || children}
            {icon && <span className={iconClasses} aria-hidden={true} />}
        </Tag>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    link: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    minWidth: PropTypes.string,
    isDownload: PropTypes.bool,
    untranslatedModifiers: PropTypes.arrayOf(PropTypes.string),
    attrs: PropTypes.object,
};

export default Button;
