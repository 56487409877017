import {
    isSupported,
    CookieStorage,
    MemoryStorage,
} from 'local-storage-fallback';

let storageInstance;

if (isSupported('localStorage')) {
    storageInstance = window.localStorage;
} else if (isSupported('cookieStorage')) {
    storageInstance = new CookieStorage();
} else if (isSupported('sessionStorage')) {
    storageInstance = window.sessionStorage;
} else {
    storageInstance = new MemoryStorage();
}

export default storageInstance;
